/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


          //swiper slider
          var swiper = new Swiper('.swiper-container', {
              slidesPerView: 4,
              spaceBetween: 30,
              loop:true,
              centeredSlides: true,
              initialSlide:1,
              pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
              },
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              },
              breakpoints: {
                  1200: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                  },
                  768: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                  },
                  640: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                  }
              }
          });
          //show navbar on scrolling back
          var didScroll;
          var lastScrollTop = 0;
          var delta = 5;
          var navbarHeight = jQuery('.rij-bar').outerHeight();

          jQuery(window).scroll(function(event){
              didScroll = true;
          });
          function hasScrolled() {
              var st = jQuery(this).scrollTop();

              // Make sure they scroll more than delta
              if(Math.abs(lastScrollTop - st) <= delta){
                  return;
              }


              // If they scrolled down and are past the navbar, add class .nav-up.
              // This is necessary so you never see what is "behind" the navbar.
              if (st > lastScrollTop && st > navbarHeight){
                  // Scroll Down
                  jQuery('.rij-bar').removeClass('nav-down').addClass('nav-up');
              } else {
                  // Scroll Up
                  if(st + jQuery(window).height() < jQuery(document).height()) {
                      jQuery('.rij-bar').removeClass('nav-up').addClass('nav-down');
                  }
              }

              lastScrollTop = st;
          }
          setInterval(function() {
              if (didScroll) {
                  hasScrolled();
                  didScroll = false;
              }
          }, 250);
          $(document).ready(function(){
              $('.toggle-overlay').click(function() {
                  $('aside').toggleClass('open');
              });
              $('.nav-mobile li a').on("click", function () {
                  $('aside').toggleClass('open');
              })
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
